<template>
  <v-container fluid>
    <FilterHeader
      @newValues="updateValues($event)"
      :use-date="true"
      :use-channels="false"
      :use-locations="false"
    />
    <div :key="transientRefreshKey">
      <TransientDash
        :title="titleWithDate"
        namespace="task_list"
        :config="{}"
        :fixed-header="true"
        :channels="channels"
        :locations="locations"
        :start-time-in-millis="startTimeInMillis"
        :end-time-in-millis="endTimeInMillis"
        style="margin-bottom: 20px;"
      />
    </div>
  </v-container>
</template>

<script>
import FilterHeader from "@/components/universalFilter/FilterHeader.vue"
import TransientDash from "@/components/dash/TransientDash"

export default {
	name: "TaskLog",
	components: {
		TransientDash,
		FilterHeader
	},
	data() {
		const now = new Date().getTime()
		return {
			oneDayInMillis: 86400000,
			transientRefreshKey: 0,
			startTimeInMillis: now - (this.oneDayInMillis * 7), // 7 days ago
			endTimeInMillis: now,
			locations: [],
			channels: [],
		}
	},
	computed: {
		titleWithDate() {
			const startString = new Date(this.startTimeInMillis)
				.toString()
				.slice(0, 24)
			const endString = new Date(this.endTimeInMillis - 1)
				.toString()
				.slice(0, 24)
			return this.$t('Task Log') + ": " + startString + " - " + endString
		}
	},
	methods: {
		updateValues(payload) {
			this.startTimeInMillis = payload.startTimeInMillis
			this.endTimeInMillis = payload.endTimeInMillis
			this.locations = payload.locations
			this.channels = payload.channels
			this.transientRefreshKey += 1
		}
	}
}
</script>
